import { render, staticRenderFns } from "./Background.vue?vue&type=template&id=955d8262&scoped=true&"
import script from "./Background.js?vue&type=script&lang=js&"
export * from "./Background.js?vue&type=script&lang=js&"
import style0 from "./Background.less?vue&type=style&index=0&id=955d8262&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "955d8262",
  null
  
)

export default component.exports